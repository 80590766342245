export const BLOG_TEMPLATE = `# January 2nd, 2024

## Coding Summary

Today,

## Learnings

- Learned

## Goals

1. Update

## Cool Snippet

\`\`\`javascript

\`\`\`

First

## Other

`;
